import AboutUsHeader from 'components/AboutUs/AboutUsHeader'
import WhiteNavbar from 'components/Navbars/WhiteNavbar'
import React, { useEffect } from 'react'
import { Badge, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
import { FaCheck } from "react-icons/fa";
import Footer from 'components/Footers/Footer';

const AboutUs = () => {
  document.body.classList.add("sidebar-collapse");
  useEffect(() => {
    AOS.init();
  }, [])
  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
  
    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector('.gt-current-lang img');
      const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement)
      imgElement.src = newImgSrc;
      imgElement.width="32"
        imgElement.height="32"
    }
  }, []);
  return (
    <>
    <Helmet>
      <title>HAKKIMIZDA | ESAYAN BIGBAG</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Esayan Bigbag sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        data-rh="true"
      />
      <link rel="canonical" href='https://www.esayanbigbag.com.tr/hakkimizda' />
      <meta property="og:title" content="HAKKIMIZDA | ESAYAN BIGBAG" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Esayan Bigbag sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.esayanbigbag.com.tr/hakkimizda" />
    </Helmet>
    <WhiteNavbar navbarSelection={"about-us"}/>
    <AboutUsHeader/>
    <div className="projects-5">
            <Container style={{ fontFamily: "Nucleo Outline" }}>
  
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/about-us/1.jpg") + ")"
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Esayan Bigbag sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar.</CardTitle>
                      <p className="card-description">
                      %100 memnuniyet garantisi
                      </p>
                      <Badge className="badge-neutral">Esayan Bigbag Tech &trade;</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto mt-5 mt-md-3" md="5" data-aos="zoom-in" data-aos-duration="2000">
                  <div className="info info-horizontal">
                  <h3 style={{display:"none"}}>Esayan Bigbag sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar.</h3>
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                      <b>Vizyonumuz</b>
                      </h4>
                      <p className="description">
                      Bigbag alanındaki sektörde lider bigbag üreticisi pozisyonumuzu tüm Dünya'ya açıp söz sahibi olmayı amaçlıyoruz.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"> <b>Misyonumuz</b></h4>
                      <p className="description">
                      Sektöre yön veren öncü kuruluş olarak Dünya standatlarında bigbag üreticisi olmak misyonu ile hareket etmekteyiz.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                      <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>Hizmet Kalitemiz</b></h4>
                      <p className="description">
                      Bigbag ürünlerini son teknolojiyi kullanarak müşterilerimiz için kaliteli ve ekonomik şartlarda bigbag üreticisi konumundayız.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col className="ml-auto order-2 mt-5 mt-md-0 order-md-1" data-aos="zoom-in" data-aos-duration="2000" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>İş Gücü</b></h4>
                      <p className="description">
                      Sadece bigbag değil aynı zamanda bigbag üreticisi olarak tüm bilgi birikimiyle tedarikçilerin yanında olan, konusunda profesyonel iş gücüne sahibiz.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>Paketleme ve Depolama</b></h4>
                      <p className="description">
                      Ürünlerin depolama ve teslimatlarının tam zamanında, istenilen kalitede ve kusursuz gerçekleşmesini sağlayan tedarik zinciri yönetiminin en iyi bigbag üreticisi konumundayız.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons ">
                        <FaCheck/>
                      </i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"><b>Online Altyapı</b></h4>
                      <p className="description">
                      Bigbag üreticisi olarak, tedarik zinciri yönetimini destekleyen kapsamlı bir IT altyapısı ile ürünlerimizi eş zamanlı olarak takip ediyor ve kalite kontrollerini gerçekleştiriyoruz.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto mt-0 mt-md-5 order-1 order-md-2" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/esayanPicture/gallery/gallery001.jpeg") + ")"
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Esayan Bigbag Ürünlerini Keşfet</CardTitle>
                      <p className="card-description text-white">
                      En üst kalitede bir deneyim sunuyoruz.
                      </p>
                      <Badge className="badge-neutral">Esayan Bigbag Tech &trade;</Badge>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
    <Footer/></>
  )
}

export default AboutUs