/*eslint-disable*/
import React, { useEffect } from "react";
import standartbigbag from "../assets/esayanPicture/standartbigbag.png"
import crosscornerbigbag from "../assets/esayanPicture/crosscornerbigbag.png"
import vetillibigbag from "../assets/esayanPicture/vetillibigbag.png"
import qbigbag from "../assets/esayanPicture/qbigbag.png"
import konteynerbigbag from "../assets/esayanPicture/konteynerbigbag.png"
import oneloopbigbag from "../assets/esayanPicture/oneloopbigbag.png"
import slingbags from "../assets/esayanPicture/slingbags.png"
import standartcuval from "../assets/esayanPicture/standartcuval.png"
import iletkenbigbag from "../assets/esayanPicture/iletkenbigbag.png"

import { BiSend } from "react-icons/bi";

import AOS from 'aos';
import 'aos/dist/aos.css';

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import WhiteNavbar from "components/Navbars/WhiteNavbar";
import Footer from "components/Footers/Footer";
import { Helmet } from "react-helmet-async";



// core components

const Urunlerimiz = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    React.useEffect(() => {
        if (window.location.href.includes("standartbigbag")) {
            const myElement = document.getElementById('standartbigbag'); window.scrollTo({
                top: myElement.offsetTop,
                behavior: 'smooth'
            })
        }
        if (window.location.href.includes("crosscornerbigbag")) {
            const myElement = document.getElementById('crosscornerbigbag'); window.scrollTo({
                top: myElement.offsetTop,
                behavior: 'smooth'
            })
        }
        if (window.location.href.includes("ventillibigbag")) {
            const myElement = document.getElementById('ventillibigbag'); window.scrollTo({
                top: myElement.offsetTop,
                behavior: 'smooth'
            })
        }
        if (window.location.href.includes("qbigbag")) {
            const myElement = document.getElementById('qbigbag'); window.scrollTo({
                top: myElement.offsetTop,
                behavior: 'smooth'
            })
        }
        if (window.location.href.includes("konteynerbigbag")) {
            const myElement = document.getElementById('konteynerbigbag'); window.scrollTo({
                top: myElement.offsetTop,
                behavior: 'smooth'
            })
        }
        if (window.location.href.includes("oneloopbigbag")) {
            const myElement = document.getElementById('oneloopbigbag'); window.scrollTo({
                top: myElement.offsetTop + 50 ,
                behavior: 'smooth'
            })
        }
        if (window.location.href.includes("slingbags")) {
            const myElement = document.getElementById('slingbags'); window.scrollTo({
                top: myElement.offsetTop + 50,
                behavior: 'smooth'
            })
        }
        if (window.location.href.includes("iletkenbigbag")) {
            const myElement = document.getElementById('iletkenbigbag'); window.scrollTo({
                top: myElement.offsetTop + 150,
                behavior: 'smooth'
            })
        }

    }, [])
    useEffect(() => {
        // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
        const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
      
        if (targetLink) {
          // Otomatik tıklama
          targetLink.click();
          const imgElement = document.querySelector('.gt-current-lang img');
          const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
          console.log(imgElement)
          imgElement.src = newImgSrc;
          imgElement.width="32"
        imgElement.height="32"
        }
      }, []);
    return (
        <>
        <Helmet>
      <title>ÜRÜNLERİMİZ | ESAYAN BIGBAG</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Geniş ürün yelpazesi ile tüm bigbag ihtiyaçlarınıza en uygun çözümler ile hizmetinizdeyiz."
        data-rh="true"
      />
      <link rel="canonical" href='https://www.esayanbigbag.com.tr/urunlerimiz' />
      <meta property="og:title" content="ÜRÜNLERİMİZ | ESAYAN BIGBAG" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Geniş ürün yelpazesi ile tüm bigbag ihtiyaçlarınıza en uygun çözümler ile hizmetinizdeyiz." />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.esayanbigbag.com.tr/urunlerimiz" />
    </Helmet>
        <WhiteNavbar />
            <div className="projects-5 pb-0" >
                
                <Container style={{ overflow: "hidden",fontFamily: "Nucleo Outline" }}>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" data-aos="fade-down" data-aos-duration="1000" md="8">
                            <h2 className="title" >Ürünlerimizi keşfedin</h2>
                            <h3 style={{ display: "none" }}>Geniş ürün yelpazesi ile tüm bigbag ihtiyaçlarınıza en uygun çözümler ile hizmetinizdeyiz.</h3>
                            <h4 className="description">
                                Geniş ürün yelpazesi ile tüm bigbag ihtiyaçlarınıza en uygun çözümler ile hizmetinizdeyiz.
                            </h4>
                            
                        </Col>
                    </Row>
                    <div id="standartbigbag" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; Standart Bigbag &#8764;</h3>
                        </Col>
                        <Col className="ml-auto" style={{ alignSelf: "center" }} md="5" data-aos="fade-down-right" data-aos-duration="3000" >
                            <img alt="standartbigbag" src={standartbigbag} width="auto" height="auto" title="quartz" loading="lazy" />
                        </Col>
                        <Col className="mr-auto" md="5" data-aos="fade-left" data-aos-duration="3000">
                            <div className="info info-horizontal" data-aos-offset="100">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikler:</b></h4>
                                    <p className="description">
                                        Düz dokuma veya dairesel dokuma polipropilen kumaştan, lamine veya lamine olmayan formlarda üretilen bu big bag'ler, 250 kg'dan 2000 kg'a kadar olan özel ihtiyaçlarınıza özel olarak tasarlanmıştır. 5:1 ve 6:1 güvenlik ağırlık yükleme faktörleriyle üretilirler.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal" >
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                        Standart big bagler daha çok tarım, inşaat gibi sektörlerde kullanılmaktadır. Ve madencilik. Ek bir naylon astar eklendiğinde, gıda veya kimyasalların taşınmasında da kullanılır.
                                    </p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <div id="crosscornerbigbag" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; Cross Corner Bigbag &#8764;</h3>
                        </Col>
                        <Col className="ml-auto mt-5 mt-md-0 order-3 order-md-2" data-aos="fade-right" data-aos-duration="3000" md="5">
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons ">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikler</b></h4>
                                    <p className="description">
                                        Kumaş dikim şeklinden dolayı ihracat yapan firmalar tarafından talep görmektedir. Gemi yüklemelerinde tercih edilen bir bigbag modelidir. Taşıma ve depolama esnasında alandan tasarruf sağlar.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                        Madenler, kimyasallar, inşaat malzemeleri, tarım ve gıda ürünleri gibi, granül ve küçük parçalı maddeler için idealdir.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="ml-auto order-2 order-md-3" data-aos="fade-down-left" data-aos-duration="3000" md="5" style={{ alignSelf: "center" }}>
                            <img alt="crosscornerbigbag" src={crosscornerbigbag} width="auto" height="auto" title="dekton" loading="lazy" />
                        </Col>
                    </Row>
                    <div id="ventillibigbag" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; Ventilli Bigbag &#8764;</h3>
                        </Col>
                        <Col className="ml-auto" data-aos="fade-down-right" data-aos-duration="3000" md="5">
                            <img alt="vetillibigbag" src={vetillibigbag} width="auto" height="auto" title="granite" loading="lazy" />
                        </Col>
                        <Col className="mr-auto" data-aos="fade-left" data-aos-duration="3000" md="5">
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikleri:</b></h4>
                                    <p className="description">
                                        Hava teması gerektiren ürünlerin ambalajlanmasında 13-26 ventilli kumaştan üretien ürünlerdir.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                        Ventilli Big bag torbalar genellikle fındık, patates gibi tarım ürünler ve odun taşımacılığında kullanılır.
                                    </p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <div id="qbigbag" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; Q Bigbag &#8764;</h3>
                        </Col>
                        <Col className="ml-auto mt-5 mt-md-0 order-3 order-md-2" data-aos="fade-right" data-aos-duration="3000" md="5">
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikler</b></h4>
                                    <p className="description">
                                        Ürününüzün taşındığı ve stoklandığı alanı optimum şekilde kullanmak için Q bag torbalar seçilmelidir. İçinde bulunan gergi panelleri sayesinde ürünün daha dik durması amaçlanmıştır. Stok veya sevkiyat aşamasında yer tasarrufu sağlar.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons"><BiSend /></i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                        Q-bag’ler genellikle gıda maddelerinin ve kimyasal maddelerin taşınmasında kullanılır.
                                    </p>
                                </div>
                            </div>

                        </Col>
                        <Col className="ml-auto order-2 order-md-3" data-aos="fade-down-left" data-aos-duration="3000" md="5">
                            <img alt="qbigbag" src={qbigbag} width="auto" height="auto" title="marble" loading="lazy" />
                        </Col>
                    </Row>
                    <div id="konteynerbigbag" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; Konteyner Bigbag &#8764;</h3>
                        </Col>
                        <Col className="ml-auto" data-aos="fade-down-right" data-aos-duration="3000" md="5">
                            <img alt="konteynerbigbag" src={konteynerbigbag} width="auto" height="auto" title="quartzite" loading="lazy" />
                        </Col>
                        <Col className="mr-auto" data-aos="fade-left" data-aos-duration="3000" md="5">
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikler:</b></h4>
                                    <p className="description">
                                        Geniş hacme sahip ekonomik bir ürün taşıma sistemidir. Granül ve tozlar gibi kuru akışkan malzemeleri taşıma imkanı sağlar. Konteyner bigbag kullanıyorsanız, yüklerinizi kamyonlarla taşıyarak yük yerine getirebilirsiniz.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                        Konteyner Bigbag tek seferde daha çok ürün taşıyarak, taşıma maliyetlerini azaltmaktadır. Bu sistemle , Buğday, mercimek, nohut, pirinç ve benzeri kuru dökme ürünlerini kolaylıkla taşıyabilirsiniz.
                                    </p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <div id="oneloopbigbag" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; One Loop Bigbag &#8764;</h3>
                        </Col>
                        <Col className="ml-auto mt-5 mt-md-0 order-3 order-md-2" data-aos="fade-right" data-aos-duration="3000" md="5">
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons ">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikler</b></h4>
                                    <p className="description">
                                        Bu tür çantalar, özellikle malzeme kaldırıcılarla yükleri yukarı çekmek için uygundur.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                        Özellikle gübre ve çimento, tohum, balık yemi ve metaller vb. kullanılır.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="ml-auto order-2 order-md-3" data-aos="fade-down-left" data-aos-duration="3000" md="5" style={{ alignSelf: "center" }}>
                            <img alt="oneloopbigbag" src={oneloopbigbag} width="auto" height="auto" title="dekton" loading="lazy" />
                        </Col>
                    </Row>
                    <div id="slingbags" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; Sling Bags &#8764;</h3>
                        </Col>
                        <Col className="ml-auto" data-aos="fade-down-right" data-aos-duration="3000" md="5" style={{ alignSelf: "center" }}>
                            <img alt="slingbags" src={slingbags} width="auto" height="auto" title="granite" loading="lazy" />
                        </Col>
                        <Col className="mr-auto" data-aos="fade-left" data-aos-duration="3000" md="5">
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikleri:</b></h4>
                                    <p className="description">
                                        Sling Bag, ahşap paletler kullanılmadan 25-50 kg arası çok sayıda torbanın taşınmasında kullanılır. Sapanlar, yüksek mukavemetli polipropilen dar kumaştan yapılmıştır. 0,5-2,5 metrik ton arası yükleri kaldırabilen kaldırma kayışları, küçük torbaların hızlı, kolay ve ekonomik bir şekilde taşınması için nakliye ve depolama sırasında kullanılır. Bu tasarım, küçük torbaların yeniden paketlenmesi ihtiyacını ortadan kaldırır ve palet kullanılmadığı için maliyet tasarrufu sağlar. Tasarım güvenlik faktörü 5:1'dir.
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                        Ağırlıklı olarak 25 kg’dan 50 kg’a kadar olan torbaların toplu taşımasında kullanılır (Örn.çimento, fosfat vb.)
                                    </p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <div id="iletkenbigbag" className="section-space" style={{height:"6rem"}}></div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h3 className="title">&#8764; İletken Bigbag &#8764;</h3>
                        </Col>
                        <Col className="ml-auto mt-5 mt-md-0 order-3 order-md-2" data-aos="fade-right" data-aos-duration="3000" md="5">
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons ">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Özellikler</b></h4>
                                    <p className="description">
                                    B Tipi Big Bag olarak da bilinen İletken Bigbag'ler, özellikle yanıcı maddelerin bulunduğu ortamlarda doldurma ve boşaltma işlemleri sırasında statik elektrik riskini azaltmak için kullanılan büyük torbalardır
                                    </p>
                                </div>
                            </div>
                            <div className="info info-horizontal">
                                <div className="icon icon-info">
                                    <i className="now-ui-icons">
                                        <BiSend />
                                    </i>
                                </div>
                                <div className="description">
                                    <h4 className="info-title"><b>Kullanım Alanları:</b></h4>
                                    <p className="description">
                                    İletken Bigbag'ler kimya endüstrisi, ilaç endüstrisi, gıda endüstrisi, tarım, madencilik ve mineraller, inşaat ve atık yönetimi dahil olmak üzere çeşitli sektörlerde uygulama alanı bulmaktadır. Bu sektörlerde güvenliğin sağlanmasında ve statik kaynaklı risklerin önlenmesinde çok önemli bir rol oynarlar.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="ml-auto order-2 order-md-3" data-aos="fade-down-left" data-aos-duration="3000" md="5" style={{ alignSelf: "center" }}>
                            <img alt="iletkenbigbag" src={iletkenbigbag} width="auto" height="auto" title="dekton" loading="lazy" />
                        </Col>
                    </Row>
                </Container>

            </div>
            <Footer />
        </>
    );
};

export default Urunlerimiz;
