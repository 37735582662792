/*eslint-disable*/
import React from "react";
import 'aos/dist/aos.css';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Footer() {
  
  return (
    <>
      <footer className="footer" style={{ fontFamily: "Nucleo Outline" }}>
        <br/><hr/><br/>
        <Container >
          <div className="content text-center">
            <Row>
              <Col md="2" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Menü</b></h1>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/"
                
                    >
                      Ana Sayfa
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/hakkimizda"
                     
                    >
                      Hakkımızda
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz"
                      
                    >
                      Ürünlerimiz
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/sertifikalar"
                      
                    >
                      Sertifikalar
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="text-muted"
                      href="/contact-us"
                      
                    >
                      Bizden Haberler
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="text-muted"
                      href="/galeri"
                      
                    >
                      Galeri
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/iletisim"
                      
                    >
                      İletişim
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="2" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Ürünlerimiz</b></h1>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#standartbigbag"
                      
                    >
                      Standart Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#crosscornerbigbag"
                     
                    >
                      Cross Corner Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#ventillibigbag"
                     
                    >
                      Ventilli Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#qbigbagid"
                      
                    >
                      Q Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#konteynerbigbag"
                      
                    >
                      Konteyner Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#oneloopbigbag"
                      
                    >
                      One Loop Bigbag
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#slingbags"
                      
                    >
                      Sling Bags
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/urunlerimiz/#iletkenbigbag"
                      
                    >
                      İletken Bigbag
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Adres</b></h1>
                <div className="social-feed">
                  <div className="feed-line">
                    
                    <p>
                    Demirciler Mah. Demirci Sok. No:60/2 Dilovası/Kocaeli
                    </p>
                  </div>
                  <div className="feed-line">
                  <iframe title="esayanbigbagadres" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12075.986802615414!2d29.54298190366345!3d40.82803613763801!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb21b159fa6637%3A0xb2ff7106472f16bd!2sEsayan%20Grup!5e0!3m2!1sen!2str!4v1708198144037!5m2!1sen!2str" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.531833766775!2d-74.2563547463899!3d40.8821473000735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c301af8a1bfabd%3A0x365bb5d9a53e898f!2s1%20Fairfield%20Rd%2C%20Caldwell%2C%20NJ%2007006%2C%20Amerika%20Birle%C5%9Fik%20Devletleri!5e0!3m2!1str!2str!4v1689485474032!5m2!1str!2str" width="auto" height="auto" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                  </div>
                </div>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Bizi Takip Edin</b></h1>
                <ul className="social-buttons">
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="instagram"
                      target="_blank"
                      href="https://www.instagram.com/esayanbigbag/"
                      aria-label="Instagram ESAYAN BIGBAG"
                    >
                      <i className="fab fa-instagram"></i>
                      <span style={{display:"none"}}>Instagram ESAYAN BIGBAG</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="facebook"
                      target="_blank"
                      href="/"
                      aria-label="Facebook ESAYAN BIGBAG"
                    >
                      <i className="fab fa-facebook-square"></i>
                      <span style={{display:"none"}}>Facebook ESAYAN BIGBAG</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="linkedin"
                      href="/"
                      rel="ESAYAN BIGBAG LinkedIn Account"
                      text="ESAYAN BIGBAG LinkedIn Account"
                      target="_blank"
                      aria-label="LinkedIn ESAYAN BIGBAG"
                    >
                      <i className="fab fa-linkedin"></i>
                      <span style={{display:"none"}}>LinkedIn ESAYAN BIGBAG</span>
                    </Button>
                  </li>
                  {/* <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="pinterest"
                      href="/"
                      target="_blank"
                      
                    >
                      <i className="fab fa-pinterest-square"></i>
                      <span style={{display:"none"}}>Pinterest ESAYAN BIGBAG</span>
                    </Button>
                  </li> */}
                </ul>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                  <small><b><u>E-mail</u></b></small>
                </h1>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                info@esayanambalaj.com.tr
                </h1>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                  <small><b><u>Telefon</u></b></small>
                </h1>
                <h1 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                0 262 644 04 25
                </h1>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Powered by{" "}<a className="text-dark" href="/">Sisprime Digital Solutions</a>
            
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
