
import React from "react";
import { HashLink } from "react-router-hash-link";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

const AnasayfaUrunler = () => {
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  
  return (
    <>
      {/* <Helmet>
        <title>
          Master Drivers Blog -  Expert Driving Tips & Valuable Information
        </title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Explore Master Drivers Blog for expert driving tips and valuable information. Enhance your skills and stay informed on the road. Discover essential content now"
          data-rh="true"
        />
        <link rel="canonical" to="/blog" />
        <meta
          property="og:title"
          content="Master Drivers Blog -  Expert Driving Tips & Valuable Information"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Explore Master Drivers Blog for expert driving tips and valuable information. Enhance your skills and stay informed on the road. Discover essential content now"
        />
        <meta
          property="og:image"
          content=""
        />
        <meta
          property="og:url"
          content="https://www.masterdrivers.ca/blog"
        />
      </Helmet> */}
      <div style={{ fontFamily: "Nucleo Outline" }}>
        <Container>
          <div className="section">
            <div className="title">
              <br></br>
              
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274" }}
                data-aos="fade-right" data-aos-duration="1000"
              >
                <b>ÜRÜNLERİMİZ</b>
              </h2>
            </div>
            <br></br>
            <Row className="justify-content-center">
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/urunlerimiz/#standartbigbag" style={{border:"none"}} aria-label="Standart Bigbag ürününe git">
                      {/* <img
                        alt="..."
                        className="img rounded img-raised"
                        src={require("assets/esayanPicture/oneloopbigbag.mp4")}
                      ></img> */}
                      <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/standartbigbag.mp4")} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    </HashLink>
                  </div>
                  <CardBody>
                  {/* <h1 style={{display:"none"}}>Esayan Bigbag Güvenilir Hizmet</h1>
                  <h2 style={{display:"none"}}>Esayan Bigbag Güvenilir Hizmet</h2>
                  <h3 style={{display:"none"}}>Standart Bigbag Güvenilir Hizmet</h3> */}
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#standartbigbag"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="Standart Bigbag ürününe git" 
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; STANDART BIGBAG &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/urunlerimiz/#crosscornerbigbag" aria-label="Cross Corner Bigbag ürününe git" style={{border:"none"}}>
                      {/* <img
                        alt="..."
                        className="img rounded img-raised"
                        src={require("assets/esayanPicture/vetillibigbag.mp4")}
                      ></img> */}
                      <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/crosscornerbigbag.mp4")}  type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#crosscornerbigbag"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="Cross Corner Bigbag ürününe git"
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; CROSS CORNER BIGBAG &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/urunlerimiz/#ventillibigbag" style={{border:"none"}} aria-label="Ventilli Bigbag ürününe git">
                      {/* <img
                        alt="..."
                        className="img rounded img-raised"
                        src={require("assets/esayanPicture/oneloopbigbag.mp4")}
                      ></img> */}
                      <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/vetillibigbag.mp4")} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#ventillibigbag"
                        style={{ color: "#123274", border:"none"}} aria-label="Ventilli Bigbag ürününe git"
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; VENTİLLİ BIGBAG &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/urunlerimiz/#qbigbag" style={{border:"none"}} aria-label="Q Bigbag ürününe git">
                    <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/qbigbag.mp4")} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#qbigbagid" aria-label="Q Bigbag ürününe git"
                        style={{ color: "#123274", borderStyle: "none",}}
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; Q BIGBAG &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/urunlerimiz/#konteynerbigbag" style={{border:"none"}} aria-label="Konteyner Bigbag ürününe git">
                    <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/konteynerbigbag.mp4")} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#konteynerbigbag"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="Konteyner Bigbag ürününe git"
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; KONTEYNER BIGBAG &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/urunlerimiz/#oneloopbigbag" style={{border:"none"}} aria-label="One Loop Bigbag ürününe git">
                    <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/oneloopbigbag.mp4")} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#oneloopbigbag"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="One Loop Bigbag ürününe git"
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; ONE LOOP BIGBAG &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}} >
                    <HashLink to="/urunlerimiz/#slingbags" style={{border:"none"}} aria-label="Sling Bags ürününe git">
                    <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/slingbags.mp4")} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#slingbags"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="Sling Bags ürününe git"
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; SLING BAGS &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="4">
                <Card className="card-plain card-blog">
                  <div className="card-image" style={{textAlign:"-webkit-center"}}>
                    <HashLink to="/urunlerimiz/#iletkenbigbag" style={{border:"none"}} aria-label="İletken Bigbag ürününe git">
                    <video width="350" height="305.8" autoPlay loop muted>
                        <source src={require("assets/esayanPicture/iletkenbigbag.mp4")} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    </HashLink>
                  </div>
                  <CardBody>
                    <CardTitle tag="h1" className="text-center" style={{fontSize:"1.4rem",fontWeight:"400"}}>
                      <HashLink
                        to="/urunlerimiz/#iletkenbigbag"
                        style={{ color: "#123274", borderStyle: "none",}} aria-label="İletken Bigbag ürününe git"
                      >
                        <b style={{fontWeight:"bolder"}}>&#126; İLETKEN BİGBAG &#126;</b>
                      </HashLink>
                    </CardTitle>
                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </div>
        </Container>
      </div>
    </>
  );
};

export default AnasayfaUrunler;
